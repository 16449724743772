<template>
    <tr>
        <td>{{ item }}</td>
        <td>
            <template v-if="progress">
                <v-progress-circular indeterminate size="16" width="2" color="grey darken-2" />
            </template>
            <template v-if="!progress">
                <template v-if="isPositive">
                    <font-awesome-icon :icon="['fas', 'check']" color="green" fixed-width size="1x"/>
                    {{ positiveText }}
                </template>
                <template v-if="isNegative">
                    <font-awesome-icon :icon="['fas', 'times']" color="grey" fixed-width size="1x"/>
                    {{ negativeText }}
                </template>
                <template v-if="isUnknown">
                    <font-awesome-icon :icon="['fas', 'question']" color="grey darken-2" fixed-width size="1x"/>
                    {{ unknownText }}
                </template>
            </template>
        </td>
        <td>
            {{ comment }}
        </td>
    </tr>
</template>
<script>
export default {
    props: {
        item: {
            type: String,
        },
        status: {
            type: Boolean,
        },
        statusPositive: {
            type: String,
        },
        statusNegative: {
            type: String,
        },
        statusUnknown: {
            type: String,
        },
        comment: {
            type: String,
        },
        yesno: {
            type: Boolean,
            default: false,
        },
        abled: {
            type: Boolean,
            default: false,
        },
        progress: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        positiveText() {
            let text;
            if (this.statusPositive) {
                text = this.statusPositive;
            } else if (this.yesno) {
                text = 'Yes';
            } else if (this.abled) {
                text = 'Enabled';
            } else {
                text = 'OK';
            }
            return text;
        },
        negativeText() {
            let text;
            if (this.statusPositive) {
                text = this.statusPositive;
            } else if (this.yesno) {
                text = 'No';
            } else if (this.abled) {
                text = 'Disabled';
            } else {
                text = 'Failed';
            }
            return text;
        },
        unknownText() {
            let text;
            if (this.statusUnknown) {
                text = this.statusUnknown;
            } else {
                text = 'Unknown';
            }
            return text;
        },
        isPositive() {
            return this.status === true;
        },
        isNegative() {
            return this.status === false;
        },
        isUnknown() {
            return typeof this.status !== 'boolean';
        },
    },
};
</script>
