<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
                    <!-- <router-link :to="{ name: 'account-dashboard', params: { realm: this.$route.params.realmId } }">Account</router-link> --> <!-- TODO: missing account id ... -->
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">SSH access check</h1>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-5" v-if="user">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                    <v-simple-table>
                        <template #default>
                            <thead>
                                <tr>
                                    <th>Item</th>
                                    <th>Status</th>
                                    <th>Comment</th>
                                </tr>
                            </thead>
                            <tbody>
                                <CheckTableRow item="Username assigned" :status="isUsernameAssigned" yesno :comment="user.ssh_alias"/>
                                <CheckTableRow item="UID assigned" :status="isUsernameCreated" yesno :progress="isCheckInProgress" :comment="status.uid"/>
                                <CheckTableRow item="Password" :status="isPasswordEnabled" abled :progress="isCheckInProgress"/>
                                <CheckTableRow item="Public Key" :status="isPublicKeyEnabled" abled :progress="isCheckInProgress"/>
                                <!-- TODO: button to open dialog and show the public key (remember, admin might use this to look at a user's ssh status also, and the admin doesn't go to the user's profile page, so this is the only place to see it) -->
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style lang="css">
.v-input__control .v-input__append-inner {
    margin-top: 10px; /* (56 input height - 36 button height) / 2 */
    margin-bottom: 10px;
    padding: 0px;
}
</style>

<script>
import { mapState /* , mapGetters */ } from 'vuex';
import CheckTableRow from '@/components/check/CheckTableRow.vue';

// function abledTextToBoolean(text) {
//     let result;
//     if (text === 'enabled') {
//         result = true;
//     } else if (text === 'disabled') {
//         result = false;
//     } else {
//         result = null;
//     }
//     return result;
// }

export default {
    components: {
        CheckTableRow,
    },
    data: () => ({
        status: null,
        // isUsernameCreated: null,
        isCheckInProgress: false,
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
        }),
        isUsernameAssigned() {
            return typeof this.user?.ssh_alias === 'string' && this.user.ssh_alias.length > 0;
        },
        isUsernameCreated() {
            if (typeof this.status?.is_created === 'boolean') {
                return this.status.is_created;
            }
            return null;
        },
        isPasswordEnabled() {
            // return !!this.user.ssh_enable_password;
            if (typeof this.status?.password_status === 'string') {
                return this.status.password_status === 'enabled';
            }
            return null;
        },
        isPublicKeyEnabled() {
            // return !!this.user.ssh_enable_publickey;
            if (typeof this.status?.publickey_status === 'string') {
                return this.status.publickey_status === 'enabled';
            }
            return null;
        },
    },
    watch: {
    },
    methods: {
        async checkSshUserStatus() {
            try {
                this.$store.commit('loading', { checkSshUserStatus: true });
                this.isCheckInProgress = true;
                console.log('checkSshUserStatus');
                const statusResponse = await this.$client.user(this.user.id).user.check({ item: 'ssh_user_status' });
                console.log(`checkSshUserStatus: statusResponse ${JSON.stringify(statusResponse)}`);
                this.status = statusResponse;
                // if (typeof response?.is_created === 'boolean') {
                //     this.isUsernameCreated = response.is_created;
                // }
                if (this.status.ssh_alias && !this.status.is_created) {
                    const fixAliasResponse = await this.$client.user(this.user.id).user.check({ item: 'fix_ssh_alias' });
                    console.log(`checkSshUserStatus: fixAliasResponse ${JSON.stringify(fixAliasResponse)}`);
                    // TODO: based on response, update items in this.status
                    ['is_created', 'uid'].forEach((item) => {
                        if (fixAliasResponse[item]) {
                            this.$set(this.status, item, fixAliasResponse[item]);
                        }
                    });
                }

                // if (abledTextToBoolean(this.status.password_status) !== this.user.ssh_enable_password) {
                //     const fixPasswordResponse = await this.$client.user(this.user.id).user.check({ item: 'fix_ssh_password' });
                //     console.log(`checkSshUserStatus: fixPasswordResponse ${JSON.stringify(fixPasswordResponse)}`);
                //     // TODO: based on response, update items in this.status
                // }
                // if (abledTextToBoolean(this.status.publickey_status) !== this.user.ssh_enable_publickey) {
                //     const fixPublicKeyResponse = await this.$client.user(this.user.id).user.check({ item: 'fix_ssh_publickey' });
                //     console.log(`checkSshUserStatus: fixPublicKeyResponse ${JSON.stringify(fixPublicKeyResponse)}`);
                //     // TODO: based on response, update items in this.status
                // }
            } catch (err) {
                console.error('checkSshUserStatus failed', err);
            } finally {
                this.isCheckInProgress = false;
                this.$store.commit('loading', { checkSshUserStatus: false });
            }
        },
    },
    mounted() {
        this.checkSshUserStatus();
    },
};
</script>
